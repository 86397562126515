import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Apartments from "../components/Apartments"

import "./styles.scss"

const HausPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: { relativeDirectory: { eq: "haus" } }
        sort: { order: ASC, fields: relativePath }
      ) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO title="Unser Haus" />
      <section className="section">
        <div className="container">
          <nav className="breadcrumb" aria-label="breadcrumbs">
            <ul>
              <li>
                <Link to="/">Start</Link>
              </li>
              <li className="is-active">
                <Link to="/haus/" aria-current="page">
                  Unser Haus
                </Link>
              </li>
            </ul>
          </nav>
        </div>
        <div className="container">
          <div className="title has-text-centered">Unser Haus</div>
          <div className="subtitle">
            Wenn Sie einen gemütlichen, erholsamen Urlaub verbunden mit Spiel,
            Sport und Spaß in familiärer, kinderfreundlicher Atmosphäre erleben
            möchten, dann sind Sie bei uns genau richtig.
          </div>
          <div>
            Ruhig, sonnige Lage, in unmittelbarer Nähe zum Turnersee (ca. 5
            Gehminuten) und einem herrlichen Ausblick auf die Berge!
            <br />
            <br />
            Unsere große Spiel- und Liegewiese mit Kinderschaukel,
            Tischtennistisch, Tischfußballtisch sowie unerem Trampolin bieten
            viele Möglichkeiten für Kinder und Erwachsene. Unser herrlicher
            Swimmingpool (5x10m) und die Badehütte mit einem offenen Griller
            bietet eine angenehme Abwechslung, wenn man mal nicht zu einem der
            schönen Badeseen in der Region möchte. Auf unseren Liegen für Zwei
            können Sie nach dem Schwimmen Relaxen oder einfach nur die
            herrlichen Sonnenstrahlen geniessen.
            <br />
            <br />
            Für laue Sommernächte bietet sich unser Pavillon an - ob für ein
            Grillfest, ein gemeinsames Zusammensitzen oder für ein romantisches
            Dinner zu zweit.
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <Img fluid={data.allFile.nodes[0].childImageSharp.fluid} />
          <br />
          <br />
          <Img fluid={data.allFile.nodes[1].childImageSharp.fluid} />
          <br />
          <br />
          <Img fluid={data.allFile.nodes[2].childImageSharp.fluid} />
          <br />
          <br />
          <Img fluid={data.allFile.nodes[3].childImageSharp.fluid} />
          <br />
          <br />
        </div>
      </section>
      <Apartments />
    </Layout>
  )
}

export default HausPage
